import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				O nas | Odkryj TileCrafters
			</title>
			<meta name={"description"} content={"Gdzie tradycja spotyka się z innowacją"} />
			<meta property={"og:title"} content={"O nas | Odkryj TileCrafters"} />
			<meta property={"og:description"} content={"Gdzie tradycja spotyka się z innowacją"} />
			<meta property={"og:image"} content={"https://rizibo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://rizibo.com/img/326236.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://rizibo.com/img/326236.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://rizibo.com/img/326236.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://rizibo.com/img/326236.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://rizibo.com/img/326236.png"} />
			<meta name={"msapplication-TileImage"} content={"https://rizibo.com/img/326236.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Gdzie tradycja spotyka się z innowacją
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Zanurz się w sercu TileCrafters, miejsca, w którym wierzymy, że każda instalacja płytek to nie tylko usługa, ale rzemiosło, które dodaje charakteru i długowieczności Twoim przestrzeniom. Nasza historia rozpoczęła się od prostego pomysłu: połączenia ponadczasowych technik ze współczesnymi projektami, tworząc unikalne, trwałe i piękne przestrzenie dla każdego klienta. Nasze zaangażowanie w doskonałość i innowacyjność uczyniło z nas wiodącą markę w dziedzinie instalacji płytek, znaną z jakości, niezawodności i artystycznego podejścia.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://rizibo.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				margin="0px"
				font="--headline2"
				color="--dark"
				max-width="850px"
				width="50%"
				md-width="100%"
				md-margin="0px 0px 16px 0px"
			>
				Nasza podróż
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				Firma TileCrafters powstała w oparciu o przekonanie, że każdy dom ma swoją historię do opowiedzenia, a każda podłoga może być arcydziełem. Dzięki wieloletniemu doświadczeniu nasz zespół opanował sztukę układania płytek, wykorzystując zarówno tradycyjne metody, jak i nowoczesne rozwiązania. Nasza podróż jest świadectwem naszej pasji do rzemiosła i naszego zaangażowania w zapewnianie niezrównanej obsługi naszym klientom.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image max-width="340px" src="https://rizibo.com/img/7.jpg" md-margin="0px 0px 20px 0px" />
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
					Dlaczego TileCrafters się wyróżnia
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					Indywidualne projekty: Nie tylko układamy płytki - tworzymy spersonalizowane dzieła sztuki, które odzwierciedlają Twój niepowtarzalny styl.
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					Zrównoważone praktyki: Nasze zaangażowanie w ochronę środowiska oznacza stosowanie zrównoważonych materiałów i metod, gdy tylko jest to możliwe.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
					Dbałość o szczegóły: Od początkowego projektu do ostatecznego wykończenia, każdy krok jest wykonywany z precyzją i starannością.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
					Podejście zorientowane na klienta: Zadowolenie klienta jest naszym najwyższym priorytetem. Współpracujemy z Tobą na każdym etapie, aby zapewnić realizację Twojej wizji.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});